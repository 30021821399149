<template>
  <div class="Site PageBox">
    <NavBar :title="$t('wallet.default[16]')" :page-number="-1"/>
    <div class="ScrollBox">
      <van-form class="mt15 ">
        <h3 style="text-align: left;margin-bottom: 10px">{{$t('busauth[0]')}}</h3>
        <div class="Panel border-grey">
        <van-field
                :border="false"
                class="input"
                v-model.trim="postData.username"
                :placeholder="$t('busauth[1]')"
                size="large"
                clearable
                autocomplete="off"
        />
        </div>
        <div class="Panel border-grey">
        <van-field
                :border="false"
                class="input"
                v-model.trim="postData.id"
                :placeholder="$t('busauth[2]')"
                size="large"
                clearable
                autocomplete="off"
        />
        </div>
        <h4 v-if="busdeposit>0">
          {{$t('wallet.default[19]')}} : {{busdeposit}} USDT
        </h4>
        <h4 v-if="busmoney>0">
          {{$t('busAuth2[0]')}} : {{busmoney}} USDT
        </h4>
        <h3 style="text-align: left;margin-bottom: 10px;margin-top: 30px">{{$t('busauth[3]')}}</h3>
        <div class="panel border-grey">
<!--          <div style="padding: 20px">-->
<!--            <img src="../../public/static/images/bg_id.png" style="width:120px"/>-->
<!--          </div>-->
          <div style="margin: 0 auto;text-align: center;padding: 10px">
          <van-uploader v-model="postData.screenshots" max-count="1"
                        :after-read="afterRead">
            <img src="~@/assets/img/skinbitcoin/caxj.png">
            <div style="margin-top: 10px"> {{$t('busauth[4]')}}</div>
          </van-uploader>
          </div>
        </div>
        <div style="text-align: center;margin-top: 60px">
          <p class="btn gradient-btn" @click="submitAuth">{{$t('common[2]')}}</p>
        </div>
      </van-form>
    </div>
<!--      <van-dialog v-model="showNotice"  :title="$t('wallet.default[16]')"-->
<!--                  :show-confirm-button="true">-->
<!--        <div style="width: 90%;margin-top: 10px;margin-bottom: 30px;margin-left: 20px">-->
<!--          <dd v-html="InitData.helpList[1].content"></dd>-->
<!--        </div>-->
<!--      </van-dialog>-->
<!--    <van-dialog v-model="showRecharge"  :title="$t('wallet.default[21]')" @confirm="gotoRecharge"-->
<!--                :show-confirm-button="true"  :show-cancel-button="true">-->
<!--      <div style="width: 90%;margin-top: 10px;margin-bottom: 30px">-->
<!--        {{busmoney}} USDT,{{$t('wallet.default[23]')}}-->
<!--      </div>-->
<!--    </van-dialog>-->
    </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      postData: {
        username: "",
        id: "",
      },
      showCon: false,
      showNotice: false,
      showRecharge:false,
      infoData: '',
      busmoney:0,
      busdeposit:0,
    }
  },
  computed: {
    pageTitle(){
      var title = "";
      if (this.busdeposit>0){
        title += this.$t('wallet.default[19]')
      }
      if (this.busmoney>0){
        title += this.$t('busAuth2[0]')
      }
      return title
    },
  },
  watch: {
    "InitData.helpList"(data) {
      if (!this.$parent.isNotice && data.length>1) this.showNotice = true;
    },
  },
  created() {
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log(this.InitData.helpList)
    this.busmoney = parseFloat(this.UserInfo.bus_amount).toFixed(2);
    let balance = this.UserInfo.balance;
    this.busdeposit = parseFloat(this.UserInfo.bus_deposit).toFixed(2);
    if (balance<(this.busmoney+this.busdeposit)){
      this.showRecharge = true;
    }
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    },
    gotoRecharge(){
      this.$router.push("/user/mixrecharge");
    },
    submitAuth(){
      if (!this.postData.username) {
        var msg = this.$t("busauth[1]");
        this.$Dialog.Toast(msg);
        return;
      }
      if (!this.postData.id) {
        var msg = this.$t("busauth[2]");
        this.$Dialog.Toast(msg);
        return;
      }
      this.$Model.GobusAuth(this.postData,data=>{
        var msg = '';
        if (data.code==1){
          msg = this.$t('common4[1]');
        }else if (data.code==0){
          msg = data.code_dec
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.replace("/");
        }

      });
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
          } else {
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    }
  }
}
</script>
<style scoped>
  .PageBox {
    padding: 10px;
    padding-top: 50px;
  }

  .PageBox>>>.tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    background-color: var(--panel_color);
    border-radius: 10px;
    height: 120px;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    display: flex;
    color:#FFFEFF;
    align-items: center;
    padding: 20px;
  }

  .van-cell {
    background-color: transparent;
  }

  .panel {
    text-align: center;
    padding: 10px;
    background-color: var(--panel_color);
    border-radius: 10px;
    margin-top:10px;
    display: flex;
  }
.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}

.ScrollBox{
	padding:0 15px;
}
  .van-cell--borderless.input {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px 0 3px;
    height: 35px;
  }

  .van-cell--borderless.input >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 7px;
    //height: 100%;
    font-size: 14px;
  }
  .van-cell--borderless.input >>> .van-field__control::-webkit-input-placeholder {
    color: var(--cell_placeholder) !important;
    font-size: 19px;

  }

  .van-cell--borderless.input >>> .van-field__control::-moz-placeholder {
    color: var(--cell_placeholder) !important;

  }

  .van-cell--borderless.input >>> .van-field__control::-ms-input-placeholder {
    color: var(--cell_placeholder) !important;

  }
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}

.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}
  .btn {
    width: 100%;
    padding: 10px 50px;
    border-radius: 5px;
    background-color: var(--button_bg);
    color: #FFFEFF;
    font-size: 18px;
    text-align: center;
    margin: 15px 0px 30px;
  }
</style>
